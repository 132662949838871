(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  "mobile": {
    "landscape": 896,
    "portrait": 700,
    "small": 480
  },
  "tablet": {
    "landscape": 1024,
    "portrait": 768
  },
  "notebook": {
    "small": 1280,
    "normal": 1500
  }
};

},{}],2:[function(require,module,exports){
'use strict';

require('./modules/lazyload.js');

require('./modules/modals.js');

require('./modules/forms.js');

require('./modules/masks.js');

require('./modules/spinner.js');

require('./modules/scroll.js');

require('./modules/menus.js');

require('./modules/search.js');

require('./modules/tabs.js');

require('./modules/sliders.js');

require('./modules/spoilers.js');

require('./modules/compare.js');

require('./modules/history.js');

require('./modules/vuejs/vue.common.js');

require('./modules/vuejs/card/card-app.js');

require('./modules/vuejs/order/order-app.js');

},{"./modules/compare.js":3,"./modules/forms.js":4,"./modules/history.js":7,"./modules/lazyload.js":8,"./modules/masks.js":9,"./modules/menus.js":10,"./modules/modals.js":11,"./modules/scroll.js":12,"./modules/search.js":13,"./modules/sliders.js":14,"./modules/spinner.js":15,"./modules/spoilers.js":16,"./modules/tabs.js":17,"./modules/vuejs/card/card-app.js":18,"./modules/vuejs/order/order-app.js":24,"./modules/vuejs/vue.common.js":25}],3:[function(require,module,exports){
'use strict';

$(function () {
  $(window).trigger('compareInit');
});

$(window).on('compareInit imageLoaded', function () {
  var $firstProduct = $('.compare-vitrine__product:first');
  var $productsList = $('.compare-vitrine__product');

  var headerMaxHeight = Math.max.apply(null, $.map($productsList, function (item) {
    var height = $(item).find('.vitrine-product').outerHeight();
    return height;
  }));

  $productsList.each(function setHeights() {
    $(this).find('.vitrine-product').css('minHeight', headerMaxHeight);
  });

  $firstProduct.find('.compare-vitrine__product-param').each(function paramHandler() {
    var $param = $(this);
    var index = $param.index();
    var contentMaxHeight = Math.max.apply(null, $.map($productsList, function (item) {
      var height = $(item).find('.compare-vitrine__product-param:eq(' + index + ')').height('auto').height();
      return height;
    }));
    $productsList.each(function setHeights() {
      $(this).find('.compare-vitrine__product-param:eq(' + index + ')').height(contentMaxHeight);
    });
  });
});

},{}],4:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

// Selectric
// ---------

_globals.$DOCUMENT.on('initSelectric yiiListViewUpdated', function () {
  $('select:not(.no-default-selectric)').selectric({
    disableOnMobile: false,
    nativeOnMobile: true
  });
}).trigger('initSelectric');

// Checkboxes
// ----------

/* global autosize */

_globals.$BODY.on('change initCheckboxes', '.js-checkbox input', function (event) {
  var $inp = $(event.target);
  var $label = $inp.closest('.js-checkbox');

  if ($inp.prop('checked')) {
    $label.addClass('is-checked');
  } else {
    $label.removeClass('is-checked');
  }
});
$('.js-checkbox input').trigger('initCheckboxes');

// Radio buttons
// -------------

_globals.$BODY.on('change initRadio', '.js-radio input', function (event) {
  var $inp = $(event.target);
  var $group = $('[name="' + $inp.attr('name') + '"]');
  var $labels = $group.closest('.js-radio');
  var $selectedItem = $labels.find('input').filter(':checked').closest('.js-radio');

  $labels.removeClass('is-checked');
  $selectedItem.addClass('is-checked');
});
$('.js-radio input').trigger('initRadio');

// Textarea autosize
// -----------------

$(function () {
  autosize($('textarea'));
});

// Touched inputs state
// --------------------

$('.form__input, .form__textarea').on('focus', function focusHandler() {
  $(this).closest('.form__field').addClass('is-touched');
}).on('blur', function blurHandler() {
  var $this = $(this);
  var value = $this.val();

  if (value !== '' && value != null && value !== '+7 (___) ___-__-__') return;

  $this.closest('.form__field').removeClass('is-touched');
}).on('inputs-init', function inputsInit() {
  var $this = $(this);
  var value = $this.val();

  if (value !== '' && value != null && value !== '+7 (___) ___-__-__') {
    $this.closest('.form__field').addClass('is-touched');
  }
}).trigger('inputs-init');

// Fileinputs
// ----------

_globals.$BODY.on('change', '.js-fileinput input', function changeHandler(event) {
  $(event.target).closest('.js-fileinput').parent().find('.js-fileinput-filename').text($(this).val());
});

// Filters

(function () {

  if (!$('.js-toggle-mobile-filters').length) return;

  var openedClass = 'filter-is-opened';

  var url = new URL(window.location);
  if (url.searchParams.get('filter')) {
    _globals.$BODY.addClass(openedClass);
  }

  $(document).on('click', '.js-toggle-mobile-filters', function (e) {
    e.preventDefault();

    _globals.$BODY.toggleClass(openedClass);

    if (_globals.$BODY.hasClass(openedClass)) {
      url = new URL(window.location);
      url.searchParams.set('filter', 'opened');
      window.history.pushState({}, '', url);
    } else {
      url = new URL(window.location);
      if (url.searchParams.get('filter')) {
        window.history.back();
      }
    }
  });

  $(document).on('click.close-mobile-filters', function (e) {
    var $target = $(e.target);
    if ($target.closest('.filter').length || $target.hasClass('js-toggle-mobile-filters')) return;
    if (_globals.$BODY.hasClass(openedClass)) {
      $('.js-toggle-mobile-filters').first().click();
    }
  });

  window.addEventListener('popstate', onPopstate);

  function onPopstate() {
    url = new URL(window.location);
    if (!url.searchParams.get('filter')) {
      _globals.$BODY.removeClass(openedClass);
    } else {
      _globals.$BODY.addClass(openedClass);
    }
  }

  $(document).on('click', '.js-toggle-filter-list', function toggleList(e) {
    e.preventDefault();
    $(this).closest('.js-filter-list').toggleClass('is-expanded');
  });
})();

},{"./globals.js":5}],5:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.$BODY = exports.$HTML = exports.$DOCUMENT = exports.$WINDOW = exports.HEADER_HEIGHT = exports.NOTEBOOK_WIDTH = exports.SMALL_NOTEBOOK_WIDTH = exports.TABLET_WIDTH = exports.PORTRAIT_TABLET_WIDTH = exports.LANDSCAPE_MOBILE_WIDTH = exports.MOBILE_WIDTH = exports.SMALL_MOBILE_WIDTH = exports.IS_TOUCH_DEVICE = exports.IS_IE = exports.IS_IOS = exports.IS_DESKTOP = exports.IS_MOBILE = undefined;

var _mediaQueriesConfigJson = require('../../media-queries-config.json.js');

var _mediaQueriesConfigJson2 = _interopRequireDefault(_mediaQueriesConfigJson);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// Const
// -----

var IS_MOBILE = exports.IS_MOBILE = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i) !== null;
var IS_DESKTOP = exports.IS_DESKTOP = !IS_MOBILE;
var IS_IOS = exports.IS_IOS = navigator.userAgent.match(/(iPad|iPhone|iPod)/g);
var IS_IE = exports.IS_IE = navigator.appVersion.indexOf('MSIE') !== -1 || navigator.userAgent.match(/Trident.*rv[ :]*11\./);
var IS_TOUCH_DEVICE = exports.IS_TOUCH_DEVICE = 'ontouchstart' in document;

var HTML = document.documentElement;

HTML.classList.remove('no-js');

if (IS_MOBILE) HTML.classList.add('is-mobile');
if (IS_DESKTOP) HTML.classList.add('is-desktop');
if (IS_IOS) HTML.classList.add('is-ios');
if (IS_IE) HTML.classList.add('is-ie');
if (IS_TOUCH_DEVICE) HTML.classList.add('is-touch-device');

var SMALL_MOBILE_WIDTH = exports.SMALL_MOBILE_WIDTH = _mediaQueriesConfigJson2.default.mobile.small;
var MOBILE_WIDTH = exports.MOBILE_WIDTH = _mediaQueriesConfigJson2.default.mobile.portrait;
var LANDSCAPE_MOBILE_WIDTH = exports.LANDSCAPE_MOBILE_WIDTH = _mediaQueriesConfigJson2.default.mobile.landscape;
var PORTRAIT_TABLET_WIDTH = exports.PORTRAIT_TABLET_WIDTH = _mediaQueriesConfigJson2.default.tablet.portrait;
var TABLET_WIDTH = exports.TABLET_WIDTH = _mediaQueriesConfigJson2.default.tablet.landscape;
var SMALL_NOTEBOOK_WIDTH = exports.SMALL_NOTEBOOK_WIDTH = _mediaQueriesConfigJson2.default.notebook.small;
var NOTEBOOK_WIDTH = exports.NOTEBOOK_WIDTH = _mediaQueriesConfigJson2.default.notebook.normal;

var HEADER_HEIGHT = exports.HEADER_HEIGHT = $('.header').height();

// Selectors
// ---------

var $WINDOW = exports.$WINDOW = $(window);
var $DOCUMENT = exports.$DOCUMENT = $(document);
var $HTML = exports.$HTML = $(document.documentElement);
var $BODY = exports.$BODY = $(document.body);

// Custom vh for mobile devices
// ----------------------------
// css example:
// height: calc(var(--vh, 1vh) * 100);

$(window).on('resize initVh', function () {
  var vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', vh + 'px');
}).trigger('initVh');

// Trigger fonts loaded event
// --------------------------

if (document.fonts !== undefined) {
  document.fonts.onloadingdone = function fontsLoadedCallback() {
    $(document).trigger('fontsLoaded');
    $(window).trigger('fontsLoaded');
  };
}

},{"../../media-queries-config.json.js":1}],6:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IS_SMALL_MOBILE_WIDTH = exports.IS_LANDSCAPE_MOBILE_WIDTH = exports.IS_MOBILE_WIDTH = exports.IS_TABLET_WIDTH = exports.IS_SMALL_NOTEBOOK_WIDTH = exports.IS_NOTEBOOK_WIDTH = exports.IS_DESKTOP_WIDTH = exports.WINDOW_HEIGHT = exports.WINDOW_WIDTH = undefined;
exports.status = status;

var _globals = require('./globals.js');

/* eslint-disable import/no-mutable-exports */
var WINDOW_WIDTH = exports.WINDOW_WIDTH = window.innerWidth || _globals.$WINDOW.width();
var WINDOW_HEIGHT = exports.WINDOW_HEIGHT = _globals.$WINDOW.height();
/* eslint-enable import/no-mutable-exports */
_globals.$WINDOW.resize(function () {
  exports.WINDOW_WIDTH = WINDOW_WIDTH = window.innerWidth || _globals.$WINDOW.width();
  exports.WINDOW_HEIGHT = WINDOW_HEIGHT = _globals.$WINDOW.height();
});

var IS_DESKTOP_WIDTH = exports.IS_DESKTOP_WIDTH = function IS_DESKTOP_WIDTH() {
  return WINDOW_WIDTH > _globals.NOTEBOOK_WIDTH;
};
var IS_NOTEBOOK_WIDTH = exports.IS_NOTEBOOK_WIDTH = function IS_NOTEBOOK_WIDTH() {
  return WINDOW_WIDTH > _globals.SMALL_NOTEBOOK_WIDTH && WINDOW_WIDTH <= _globals.NOTEBOOK_WIDTH;
};
var IS_SMALL_NOTEBOOK_WIDTH = exports.IS_SMALL_NOTEBOOK_WIDTH = function IS_SMALL_NOTEBOOK_WIDTH() {
  return WINDOW_WIDTH > _globals.TABLET_WIDTH && WINDOW_WIDTH <= _globals.SMALL_NOTEBOOK_WIDTH;
};
var IS_TABLET_WIDTH = exports.IS_TABLET_WIDTH = function IS_TABLET_WIDTH() {
  return WINDOW_WIDTH >= _globals.PORTRAIT_TABLET_WIDTH && WINDOW_WIDTH <= _globals.TABLET_WIDTH;
};
var IS_MOBILE_WIDTH = exports.IS_MOBILE_WIDTH = function IS_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.MOBILE_WIDTH;
};
var IS_LANDSCAPE_MOBILE_WIDTH = exports.IS_LANDSCAPE_MOBILE_WIDTH = function IS_LANDSCAPE_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.LANDSCAPE_MOBILE_WIDTH;
};
var IS_SMALL_MOBILE_WIDTH = exports.IS_SMALL_MOBILE_WIDTH = function IS_SMALL_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.SMALL_MOBILE_WIDTH;
};

// Response status
function status(response) {
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response;
}

},{"./globals.js":5}],7:[function(require,module,exports){
'use strict';

var $backLink = $('.js-history-back');
var currentUrl = window.location.href;

if ($backLink.length) {
  if (document.referrer === '') {
    $backLink.css('visibility', 'hidden');
  } else {
    $backLink.click(function () {
      window.history.back();
      setTimeout(function () {
        // if location was not changed in 100 ms, then there is no history back
        if (currentUrl === window.location.href) {
          window.close();
        }
      }, 100);
    });
  }
}

},{}],8:[function(require,module,exports){
'use strict';

(function () {

  var lazyloadImages = document.querySelectorAll('.js-lazy-pic');
  var imageIntersectionObserver = void 0;
  var completed = false;
  var lazyloadThrottleTimeout = void 0;

  document.addEventListener('DOMContentLoaded', function () {
    initLazyLoad();
    addImagesListUpdateHandlers();
  });

  function initLazyLoad() {
    if (supportsIntersectionObserver()) return observeImages();
    return observerFallback();
  }

  function supportsIntersectionObserver() {
    return 'IntersectionObserver' in window;
  }

  function observeImages() {
    createObserver();
    lazyloadImages.forEach(function (image) {
      imageIntersectionObserver.observe(image);
    });
  }

  function createObserver() {
    imageIntersectionObserver = new IntersectionObserver(function (entries) {
      processImages(entries);
    }, {
      rootMargin: '200px'
    });
  }

  function processImages(images) {
    images.forEach(function (item) {
      if (item.isIntersecting) {
        changeSrc(item.target);
        imageIntersectionObserver.unobserve(item.target);
      }
    });
  }

  function changeSrc(image) {
    image.src = image.dataset.lazy;
    image.classList.remove('js-lazy-pic');
    setTimeout(function () {
      $(window).trigger('imageLoaded');
    }, 100);
  }

  function observerFallback() {
    document.addEventListener('scroll', lazyload);
    window.addEventListener('resize', lazyload);
    window.addEventListener('orientationChange', lazyload);
  }

  function lazyload() {
    lazyloadImages = document.querySelectorAll('.js-lazy-pic');

    if (lazyloadThrottleTimeout) {
      clearTimeout(lazyloadThrottleTimeout);
    }

    lazyloadThrottleTimeout = setTimeout(function () {
      lazyloadImages.forEach(function (image) {
        if (imageIsReached(image)) {
          changeSrc(image);
        }
      });
      if (noMoreImagesLeft()) {
        removeLazyLoadListeners();
        completed = true;
      }
    }, 20);
  }

  function imageIsReached(image) {
    var scrollTop = window.pageYOffset;
    return image.offsetTop < window.innerHeight + scrollTop;
  }

  function noMoreImagesLeft() {
    return lazyloadImages.length === 0;
  }

  function addImagesListUpdateHandlers() {
    $(document).on('yiiListViewUpdated', function () {
      addImagesToObserver();
    });
    $('body').on('iasRender', function () {
      addImagesToObserver();
    });
  }

  function addImagesToObserver() {
    lazyloadImages = document.querySelectorAll('.js-lazy-pic');
    if (supportsIntersectionObserver()) {
      lazyloadImages.forEach(function (image) {
        imageIntersectionObserver.observe(image);
      });
    } else if (completed) {
      observerFallback();
    }
  }

  function removeLazyLoadListeners() {
    document.removeEventListener('scroll', lazyload);
    window.removeEventListener('resize', lazyload);
    window.removeEventListener('orientationChange', lazyload);
  }
})();

},{}],9:[function(require,module,exports){
'use strict';

(function () {

  function initializePhoneMasks() {
    $('input[type="tel"]').each(function initMask() {
      var $input = $(this);
      var defaultPlaceholder = $input.attr('placeholder') || '';
      var defaultPlaceholderMask = '+7 (___) ___-__-__';
      var phoneMask = '+7 (000) 000-00-00';
      var pasted = false;

      $input.on('focus', function () {
        $input.attr('placeholder', defaultPlaceholderMask);
      }).on('blur', function () {
        $input.attr('placeholder', defaultPlaceholder);
      });

      $input.on('paste drop', function () {
        var isEmpty = !$input.val();
        var element = $input.get(0);
        var selectionLength = element.selectionEnd - element.selectionStart;

        if (!isEmpty && $input.val().length !== selectionLength) {
          return;
        }

        pasted = true;
      });

      $input.on('input', function (e) {
        var value = $input.val();

        if (value.startsWith('8')) {
          if (pasted && value.length !== 11) {
            pasted = false;
            return;
          }

          pasted = false;

          e.stopPropagation();

          value = value.replace('8', '+7');
          $input.val(value);
        }
      });

      $input.mask(phoneMask);
    });
  }

  $(window).on('initializePhoneMasks', initializePhoneMasks).trigger('initializePhoneMasks');
})();

},{}],10:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

var $menus = $('.js-menu');

if (!_globals.IS_TOUCH_DEVICE) {
  $menus.on('mouseenter.js-menu', 'li', function mouseenterHandler() {
    var $this = $(this);

    clearTimeout($this.data('unhoverTimeout'));

    if ($this.hasClass('has-dropdown')) {
      $this.data('hoverTimeout', setTimeout(function () {
        $this.addClass('is-hovered');
      }, 200));
    } else {
      $this.addClass('is-hovered');
    }
  });

  $menus.on('mouseleave.js-menu', 'li', function mouseleaveHandler() {
    var $this = $(this);

    clearTimeout($this.data('hoverTimeout'));

    $this.data('unhoverTimeout', setTimeout(function () {
      $this.removeClass('is-hovered');
    }, 200));
  });
}

if (_globals.IS_TOUCH_DEVICE) {
  $menus.on('click.js-m-menu', 'a', function clickHandler(e) {
    e.preventDefault();

    var $anchor = $(this);
    var $parent = $anchor.parent();
    var hasDropdown = $parent.hasClass('has-dropdown');
    var isHovered = $parent.hasClass('is-hovered');
    var link = $anchor.attr('href');

    $parent.siblings().removeClass('is-hovered');

    if (!hasDropdown && link) {
      window.location.href = $anchor.attr('href');
    } else if (isHovered && link) {
      window.location.href = link;
    } else {
      $parent.toggleClass('is-hovered');
    }
  });
}

(function () {

  if (!$('.popup-menu').length) return;

  var openedClass = 'menu-is-opened';
  var initialUrl = new URL(window.location);
  var openedParam = initialUrl.searchParams.get('menu') === 'opened';

  if (openedParam) {
    _globals.$BODY.addClass(openedClass);
  }

  $('.js-toggle-popup-menu').click(function (e) {
    e.preventDefault();

    _globals.$BODY.toggleClass(openedClass);

    $('.popup-menu .is-hovered').removeClass('is-hovered');

    if (_globals.$BODY.hasClass(openedClass)) {
      var url = new URL(window.location);
      url.searchParams.set('menu', 'opened');
      window.history.pushState({}, '', url);

      _globals.$BODY.on('click.close-menu', function (ev) {
        var $target = $(ev.target);

        if ($target.closest('.popup-menu').length || $target.hasClass('js-toggle-popup-menu')) {
          return;
        }

        closeMenu(true);
      });
    } else {
      closeMenu(true);
    }
  });

  function closeMenu(back) {
    _globals.$BODY.removeClass(openedClass).off('click.close-menu');

    var url = new URL(window.location);
    if (url.searchParams.get('menu') && back) {
      window.history.back();
    }
  }

  window.addEventListener('popstate', onPopstate);

  function onPopstate() {
    if (_globals.$BODY.hasClass(openedClass)) {
      closeMenu(false);
    }
  }

  $('.js-toggle-mobile-menu-dropdown').click(function togglerClickHandler() {
    $(this).closest('ul').toggleClass('is-expanded');
  });

  // const hammerobject = new Hammer($('.popup-menu')[0], {});
  // hammerobject.on('swipeleft', function(e) {
  //   if ($BODY.hasClass(openedClass)) {
  //     closeMenu(true);
  //   }
  // });

  $('.js-toggle-mobile-menu').click(function (e) {
    e.preventDefault();

    $('.header__nav').toggleClass('is-expanded');
    $('.header').toggleClass('is-expanded');
  });
})();

},{"./globals.js":5}],11:[function(require,module,exports){
'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; /* global initCardApp */

var _globals = require('./globals.js');

var OVERLAY_LOADER_DEFAULTS = {
  hideSelector: '.js-close-popup',
  fixedElements: '.js-fixed-element',
  onShow: function onShow() {
    _globals.$DOCUMENT.trigger('popupShown');
  }
};

_globals.$DOCUMENT.on('click.overlay-open', '.js-overlay', function clickHandler(e) {
  e.preventDefault();

  var $popup = $(this).attr('href');

  $.overlayLoader(true, _extends({
    node: $popup
  }, OVERLAY_LOADER_DEFAULTS));
});

_globals.$DOCUMENT.on('click.map-overlay-open', '.js-map-content-overlay', function clickHandler(e) {
  e.preventDefault();

  var $popup = $(this).attr('href');

  $.overlayLoader(true, _extends({
    node: $popup
  }, OVERLAY_LOADER_DEFAULTS, {
    onShow: function onShow() {
      _globals.$DOCUMENT.trigger('initMapTabSliders');
    }
  }));
});

_globals.$DOCUMENT.on('click.overlay-open', '.js-ajax-overlay', function clickHandler(e) {
  e.preventDefault();

  var $self = $(this);
  var $node = $($self.attr('href'));

  $.overlayLoader(true, _extends({
    node: $node
  }, OVERLAY_LOADER_DEFAULTS));

  $.ajax({
    type: $self.data('type'),
    url: $self.data('ajax-url'),
    data: parseAjaxOverlayParams($self.data('ajax-params')),
    timeout: 10000,
    success: function success(data) {
      $node.find('.js-ajax-data').replaceWith(data);
      initCardApp('#' + $node.find('.js-card-app').attr('id'));
      setTimeout(function () {
        $(document).trigger('ajaxPopupLoaded');
      }, 350);
    },
    error: function error() {}
  });
});

function parseAjaxOverlayParams(str) {
  if (str === undefined || str === '') return {};

  if ((typeof str === 'undefined' ? 'undefined' : _typeof(str)) === 'object') return str;

  var jsonParams = JSON.parse(str);
  if (jsonParams) return jsonParams;

  var obj = {};
  var array = str.split('&');

  array.forEach(function (value) {
    var a = value.split('=');
    /* eslint-disable prefer-destructuring */
    obj[a[0]] = a[1];
    /* eslint-enable prefer-destructuring */
    return true;
  });

  return obj;
}

// Autofocus
_globals.$DOCUMENT.on('overlayLoaderShow', function (e, $node) {
  $node.find('.js-autofocus-inp').focus();
});

},{"./globals.js":5}],12:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

var _helpers = require('./helpers.js');

// Scroll to
// ---------

_globals.$DOCUMENT.on('click.scroll-to', '.js-scroll-to', function clickHandler(e) {
  e.preventDefault();

  var $link = $(this);
  var $elementToScroll = $($link.attr('href'));
  var speed = $link.data('speed') || 150;
  var offset = $link.data('offset') || $('.header').length ? $('.header').height() : 0;

  _globals.$WINDOW.scrollTo($elementToScroll, { duration: speed, offset: offset });
});

// Scrolling to top
// ----------------

if (!(0, _helpers.IS_MOBILE_WIDTH)()) {
  var $goTopButton = $('<div class="go-top-btn"></div>');

  $goTopButton.click(function () {
    _globals.$WINDOW.scrollTo(0, 200);
  });

  _globals.$WINDOW.scroll(function () {
    var scrollTop = _globals.$WINDOW.scrollTop();
    if (scrollTop > 0) {
      $goTopButton.addClass('is-visible');
    } else {
      $goTopButton.removeClass('is-visible');
    }
  });

  _globals.$BODY.append($goTopButton);
}

},{"./globals.js":5,"./helpers.js":6}],13:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

// City search

$(function () {
  var $inp = $('.js-city-search');

  $inp.keyup(function () {
    var $inpValue = $inp.val();
    var $items = $('.js-search-body a');

    $items.each(function citySearch(index, value) {
      if (!value.innerHTML.toLowerCase().includes($inpValue.toLowerCase())) {
        // !== $inpValueс
        $(this).fadeOut();
      } else {
        $(this).fadeIn();
      }
    });
  });
});

_globals.$DOCUMENT.on('click', function (event) {
  var $predictive = $('.js-search-predictive');
  var $target = $(event.target);

  if (!$target.hasClass('js-search-predictive') && !$target.parents().hasClass('js-search-predictive')) {
    _globals.$BODY.removeClass('search-is-opened');
    $predictive.fadeOut();
  }
});

},{"./globals.js":5}],14:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

$('.js-slideshow').each(function createSlider() {
  var $this = $(this);
  var $parent = $this.closest('[data-slider-root]');
  var $thumbs = $parent.find('.js-slideshow-thumbs');
  var withThumbs = $thumbs.length;

  $this.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: !withThumbs,
    arrows: true,
    appendArrows: $this.parent(),
    pauseOnHover: false,
    asNavFor: withThumbs ? $thumbs : null,
    waitForAnimate: false,
    responsive: [{
      breakpoint: _globals.PORTRAIT_TABLET_WIDTH + 1,
      settings: {
        arrows: false,
        dots: true
      }
    }]
  });
});

$('.js-slideshow-thumbs').each(function createSlider() {
  var $this = $(this);
  var $parent = $this.closest('[data-slider-root]');

  $this.slick({
    slidesToShow: 8,
    slidesToScroll: 1,
    infinite: false,
    dots: false,
    arrows: false,
    asNavFor: $parent.find('.js-slideshow'),
    focusOnSelect: true,
    responsive: [{
      breakpoint: _globals.PORTRAIT_TABLET_WIDTH + 1,
      settings: {
        slidesToShow: 6
      }
    }]
  });
});

$('.js-vitrine-slider').each(function createSlider() {
  var $this = $(this);
  var $parent = $this.parent();

  $this.slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    appendArrows: $parent,
    responsive: [{
      breakpoint: _globals.NOTEBOOK_WIDTH + 1,
      settings: {
        slidesToShow: 4
      }
    }, {
      breakpoint: _globals.TABLET_WIDTH + 1,
      settings: {
        slidesToShow: 3
      }
    }, {
      breakpoint: _globals.PORTRAIT_TABLET_WIDTH + 1,
      settings: {
        slidesToShow: 2
      }
    }, {
      breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
      settings: 'unslick'
    }]
  });
});

$('.js-gallery-slider').each(function createSlider() {
  var $this = $(this);
  var $parent = $this.parent();

  $this.slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    appendArrows: $parent,
    responsive: [{
      breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
      settings: {
        slidesToShow: 4
      }
    }]
  });
});

$(function () {
  $(window).on('sliders-init resize ajaxPopupLoaded', function () {

    $('.js-product-gallery-slider').each(function createSlider() {
      var $slider = $(this);
      var $connected = $slider.closest('[data-slider-root]').find('.js-product-thumbs-slider');

      if ($slider.hasClass('slick-initialized')) return;

      $slider.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        dots: false,
        asNavFor: $connected,
        responsive: [{
          breakpoint: _globals.PORTRAIT_TABLET_WIDTH + 1,
          settings: {
            dots: true,
            adaptiveHeight: true
          }
        }]
      });
    });

    $('.js-product-thumbs-slider').each(function createSlider() {
      var $slider = $(this);
      var $parent = $slider.parent();
      var $connected = $slider.closest('[data-slider-root]').find('.js-product-gallery-slider');

      if ($slider.hasClass('slick-initialized')) return;

      $slider.slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: false,
        arrows: true,
        dots: false,
        appendArrows: $parent,
        asNavFor: $connected,
        focusOnSelect: true
      });
    });
  }).trigger('sliders-init');
});

$('.js-scroller-dots').each(function createScrollerDots() {
  var $slider = $(this);
  var $pager = $('<ul class="slick-dots"></ul>');
  var $items = $slider.find('> *');
  var itemsObserver = void 0;
  var itemObserveThrottleTimeout = void 0;

  if ($(window).width() > _globals.SMALL_MOBILE_WIDTH) {
    $slider.next('.scroller-dots').remove();
    return false;
  }

  if (!$slider.next('.scroller-dots').length) {
    $slider.after($('<div class="scroller-dots"></div>').append($pager));

    $slider.find('> *').each(function createDot() {
      var $item = $(this);
      var $dot = $('<li><button></button></li>');
      $pager.append($dot);

      $dot.click(function () {
        $slider.scrollTo($item, 250);
      });
    });

    if ('IntersectionObserver' in window) {
      itemsObserver = new IntersectionObserver(function (entries) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            var index = $(entry.target).index();
            $pager.find('li:nth-child(' + (index + 1) + ')').addClass('is-active slick-active').siblings().removeClass('is-active slick-active');
          }
        });
      });

      $items.each(function createObserver() {
        itemsObserver.observe(this);
      });
    } else {
      $slider[0].addEventListener('scroll', changeDots);
      $pager.find('li:first-child').addClass('is-active slick-active');
      window.addEventListener('resize', changeDots);
      window.addEventListener('orientationChange', changeDots);
    }
  }

  function changeDots() {
    if (itemObserveThrottleTimeout) {
      clearTimeout(itemObserveThrottleTimeout);
    }
    itemObserveThrottleTimeout = setTimeout(function () {
      var index = void 0;
      var scrollLeft = $slider.scrollLeft();
      $items.each(function (i, item) {
        if (scrollLeft < $(item)[0].offsetLeft) {
          index = i;
          return false;
        }
        return true;
      });
      $pager.find('li:nth-child(' + (index + 1) + ')').addClass('is-active slick-active').siblings().removeClass('is-active slick-active');
    }, 20);
  }

  return $pager;
});

},{"./globals.js":5}],15:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

_globals.$DOCUMENT.on('mousedown.js-spinner', '.js-spinner-down, .js-spinner-up', function mousedownHandler() {
  var $spinnerControl = $(this);
  var $input = $spinnerControl.siblings('input');
  var step = $input.data('step') ? $input.data('step') : 1;
  var mayBeZero = $input.data('zero') ? $input.data('zero') : false;
  var value = parseInt($input.val(), 10);
  var incTimeout = void 0;
  var incInterval = void 0;
  var decTimeout = void 0;
  var decInterval = void 0;

  $spinnerControl.on('mouseup.js-spinner', clearAll).on('mouseleave.js-spinner', $spinnerControl, clearAll);

  if ($spinnerControl.hasClass('js-spinner-down')) {
    decVal();decTimeout = setTimeout(function () {
      decInterval = setInterval(decVal, 70);
    }, 300);
  }

  if ($spinnerControl.hasClass('js-spinner-up')) {
    incVal();incTimeout = setTimeout(function () {
      incInterval = setInterval(incVal, 70);
    }, 300);
  }

  function incVal() {
    if ($.isMouseLoaderActive()) return;

    value += step;
    $input.val(value).change();
  }

  function decVal() {
    if ($.isMouseLoaderActive()) return;

    if (mayBeZero) {
      if (value >= step) {
        value -= step;
        $input.val(value).change();
      }
    } else if (value > step) {
      value -= step;
      $input.val(value).change();
    }
  }

  function clearAll() {
    clearTimeout(decTimeout);clearInterval(decInterval);
    clearTimeout(incTimeout);clearInterval(incInterval);
  }
});

_globals.$DOCUMENT.on('keydown', '.js-spinner input', function (e) {
  if (isKeyToSkip(e.keyCode, e.ctrlKey)) {
    return e.keyCode;
  }
  if (isNotADigitKey(e.keyCode)) {
    e.preventDefault();
  }
  return e.keyCode;
});

_globals.$DOCUMENT.on('keyup paste', '.js-spinner input', function keyupHandler() {
  var $input = $(this);
  var mayBeZero = $input.data('zero') ? $input.data('zero') : false;

  if ($input.val() === 0 || $input.val() === '') {
    if (!mayBeZero) {
      $input.val(1);
    } else {
      $input.val(0);
    }
  }
});

function isKeyToSkip(keyCode, ctrlKey) {
  return isDelKey(keyCode) || isBackspaceKey(keyCode) || isTabKey(keyCode) || isEscKey(keyCode) || isSelectAllHotkeys(keyCode, ctrlKey) || isPositioningKey(keyCode);
}

function isDelKey(keyCode) {
  return keyCode === 46;
}

function isBackspaceKey(keyCode) {
  return keyCode === 8;
}

function isTabKey(keyCode) {
  return keyCode === 9;
}

function isEscKey(keyCode) {
  return keyCode === 27;
}

function isSelectAllHotkeys(keyCode, ctrlKey) {
  // Ctrl+A
  return keyCode === 65 && ctrlKey === true;
}

function isPositioningKey(keyCode) {
  // arrows or home / end
  return keyCode >= 35 && keyCode <= 39;
}

function isNotADigitKey(keyCode) {
  // including numpad keys
  return (keyCode < 48 || keyCode > 57) && (keyCode < 96 || keyCode > 105);
}

},{"./globals.js":5}],16:[function(require,module,exports){
'use strict';

var _helpers = require('./helpers.js');

$(function () {

  $('.js-spoiler').each(function spoilerHandler() {
    var $this = $(this);
    $(window).on('spoilersInit resize fontsLoaded', function () {
      var isExpanded = $this.hasClass('is-expanded');
      var isInsideHiddenTab = $this.closest('.tabs-panel:not(.is-active)').length;
      var isInsideCollapsedSpoiler = $this.closest('.js-spoiler:not(.is-expanded)').length;
      var isInsideFilter = $this.closest('#filter-aside').length;
      var $el = $this.find('.js-spoiler-body').first();
      var el = $el[0];
      $('.js-spoiler-body').each(function addTempState() {
        $(this)[0].style.setProperty('transition', 'none');
        $(this).find('ul').addClass('is-expanded-for-calc');
      });
      if (!isExpanded) $this.addClass('is-expanded-for-calc');
      if (isInsideCollapsedSpoiler) $this.closest('.js-spoiler').addClass('is-expanded');
      if (isInsideHiddenTab) $this.closest('.tabs-panel').addClass('is-active');
      if (isInsideFilter) $('#filter-aside')[0].style.setProperty('display', 'block');
      el.style.setProperty('--max-height', '');
      el.style.setProperty('--max-height', $el.outerHeight() + 'px');
      if (!isExpanded) $this.removeClass('is-expanded-for-calc');
      if (isInsideCollapsedSpoiler) $this.closest('.js-spoiler').removeClass('is-expanded');
      if (isInsideHiddenTab) $this.closest('.tabs-panel').removeClass('is-active');
      if (isInsideFilter) $('#filter-aside')[0].style.setProperty('display', '');
      $('.js-spoiler-body').each(function removeTempState() {
        $(this)[0].style.setProperty('transition', '');
        $(this).find('ul').removeClass('is-expanded-for-calc');
      });
    });

    if ($this.hasClass('js-save-spoiler-state')) {
      if (localStorage.getItem('SPOILER_STATE_' + $this.attr('id')) === 'true') {
        $this.addClass('is-expanded');
      }
    }
  });
  setTimeout(function () {
    $(window).trigger('spoilersInit');
  }, 0);

  $(document).on('click', '.js-toggle-spoiler', function toggleClickHandler() {
    var $this = $(this);
    var $spoiler = $this.closest('.js-spoiler');
    var scrollAfter = $this.hasClass('js-scroll-to-closed-spoiler');
    var saveState = $spoiler.hasClass('js-save-spoiler-state');
    $spoiler.toggleClass('is-expanded');
    if (scrollAfter && !$spoiler.hasClass('is-expanded')) {
      $(window).scrollTo($spoiler, { duration: 200, offset: -180 });
    }
    setTimeout(function () {
      $(window).trigger('spoilerToggled');
    }, 250);
    if (saveState) {
      localStorage.setItem('SPOILER_STATE_' + $spoiler.attr('id'), $spoiler.hasClass('is-expanded'));
    }
  });

  $('.js-toggle-mobile-spoiler').click(function mobileToggleClickHandler() {
    if (!(0, _helpers.IS_LANDSCAPE_MOBILE_WIDTH)()) return;
    $(this).closest('.js-spoiler').toggleClass('is-expanded');
    setTimeout(function () {
      $(window).trigger('spoilerToggled');
    }, 250);
  });

  $(document).on('click', '.js-filter-toggler', function changeInstructionTab(e) {
    e.preventDefault();
    var $this = $(this);

    $this.toggleClass('is-expanded');
  });

  $(document).on('tab-changed', function () {
    var $spoiler = $('.js-filter-toggler');
    if (!$spoiler) return;

    var $text = $('.tabs-nav li.is-active .js-give-text').text();

    $spoiler.text($text);
    $spoiler.removeClass('is-expanded');
  });

  // Открытие iframe на странице ресурсов
  $('.js-resources-item').click(function openIFrame(e) {
    e.preventDefault();
    var $this = $(this);
    var $href = $(this).attr('href');
    var $iframe = $('.js-resources-page__iframe');

    $iframe.attr('src', $href).parent().addClass('is-active');
    $this.parent().parent().addClass('hidden');
  });

  // Закрытие iframe и отображение списка ссылок
  $('.js-resources-page__iframe-close').click(function closeIFrame() {
    var $this = $(this);
    var $linkItem = $('.js-resources-item');

    $this.parent().removeClass('is-active');
    $linkItem.parent().parent().removeClass('hidden');
  });
});

},{"./helpers.js":6}],17:[function(require,module,exports){
'use strict';

$('.js-tabs .tabs-nav li a').click(function clickHandler(e) {
  e.preventDefault();

  var $this = $(this);
  var $panel = $($this.attr('href'));

  $this.closest('li').addClass('is-active').siblings().removeClass('is-active');
  $panel.closest('.tabs').find('.tabs-panel').removeClass('is-active').hide();
  $panel.fadeIn(function () {
    return $(document).trigger('tab-changed');
  }).addClass('is-active');
});

$(function () {
  $('.js-tabs').each(function handler() {
    $(this).find('.tabs-nav li:first a').click();
  });
});

$('.js-toggle-map-view').click(function handler(e) {
  e.preventDefault();

  var $this = $(this);

  $this.addClass('is-active').siblings().removeClass('is-active');
  $($this.attr('href')).stop(true, true).fadeIn();
  $($this.siblings().attr('href')).stop(true, true).hide();

  if ($this.attr('href') === '#map-view') {
    $('.map-section__mobile-content-link').show();
  } else {
    $('.map-section__mobile-content-link').hide();
  }
});

},{}],18:[function(require,module,exports){
'use strict';

var _PriceComponent = require('./components/PriceComponent.js');

var _PriceComponent2 = _interopRequireDefault(_PriceComponent);

var _OffersListRadioComponent = require('./components/OffersListRadioComponent.js');

var _OffersListRadioComponent2 = _interopRequireDefault(_OffersListRadioComponent);

var _OffersListSelectComponent = require('./components/OffersListSelectComponent.js');

var _OffersListSelectComponent2 = _interopRequireDefault(_OffersListSelectComponent);

var _OffersParamComponent = require('./components/OffersParamComponent.js');

var _OffersParamComponent2 = _interopRequireDefault(_OffersParamComponent);

var _OffersImageComponent = require('./components/OffersImageComponent.js');

var _OffersImageComponent2 = _interopRequireDefault(_OffersImageComponent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

window.initCardApp = function () {
  if (!$('#js-card-app').length) return false;

  var cardPriceApp = new Vue({
    el: '#js-card-app',
    components: {
      'price-component': _PriceComponent2.default,
      'offers-list-radio': _OffersListRadioComponent2.default,
      'offers-list-select': _OffersListSelectComponent2.default,
      'offers-param': _OffersParamComponent2.default,
      'offers-image': _OffersImageComponent2.default
    },
    data: function data() {
      return {
        offerInner: {},
        targetButton: ''
      };
    },

    computed: {
      offer: {
        get: function get() {
          return this.offerInner;
        },
        set: function set(offerData) {
          this.offerInner = offerData;
          this.$emit('changeOffer', this.offerInner);
          this.updateBasketButton();
        }
      }
    },
    mounted: function mounted() {
      this.$nextTick(function nextTickHandler() {
        this.offer = JSON.parse(this.$el.getAttribute('data-default-offer'));
        this.targetButton = this.$el.getAttribute('data-target-button');
        this.$emit('setDefaultOffer');
      });
    },

    methods: {
      updateBasketButton: function updateBasketButton() {
        // Обновляем данные на кнопке купить
        var data = $(this.targetButton).data();
        $.extend(data, this.offerInner.basketData);
        $(this.targetButton).data(data);
      }
    }
  });

  return cardPriceApp;
}; /* global Vue */

/**
 * Подключение в common.js
 *   import './modules/card-choose-offer.js';
 *
 * Пример подключения в шаблоне
 * <div id="js-card-app" class="product-card__controls-column"
 *   data-default-offer='<?=$model->getBasketObjectJson()?>' data-target-button=".js-cart-basket-button">

 * <offers-list-component :offers-list='<?=json_encode($offers)?>' inline-template>
 *  <div>
 *    <label class="item js-radio" v-bind:class="{'is-checked': isActive(offer.id)}" v-bind:data-size="offer.text"
 *           v-for="offer in offersList" @click="$root.offer = offer">{{offer.text}}</label>
 *  </div>
 * </offers-list-component>
 *
 * <price-component :price="this.offer.price" :old-price="this.offer.oldPrice" inline-template>
 * <div class="product-card__price-group">
 *   <div class="product-card__old-price" v-if="oldPrice">
 *     <span v-html="oldPriceFormatted"></span>
 *     <div class="product-card__discount" v-html="economyPercentFormatted"></div>
 *   </div>
 *   <div class="product-card__price" v-html="priceFormatted">
 *     <?php echo PriceHelper::price($model->getPrice(), ' <span class="currency"><span>₽</span></span>');?>
 *   </div>
 * </div>
 * </price-component>
 *
 * </div>
 *
 */


window.initCardApp();

},{"./components/OffersImageComponent.js":19,"./components/OffersListRadioComponent.js":20,"./components/OffersListSelectComponent.js":21,"./components/OffersParamComponent.js":22,"./components/PriceComponent.js":23}],19:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var OffersImage = {
  methods: {
    initImage: function initImage() {
      // console.log('Нужно обновить картинку!');
    },
    getMainImage: function getMainImage() {
      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'big';

      if (!Object.prototype.hasOwnProperty.call(this.$root.offer, 'images')) return null;

      if (!Object.keys(this.$root.offer.images).length) return null;

      if (!Object.prototype.hasOwnProperty.call(this.$root.offer.images, 'main')) return null;

      if (!Object.keys(this.$root.offer.images.main).length) return null;

      return this.$root.offer.images.main[0][type].src;
    },
    getMainImageWidth: function getMainImageWidth() {
      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'big';

      if (!this.getMainImage(type)) return null;

      return this.$root.offer.images.main[0][type].width;
    },
    getMainImageHeight: function getMainImageHeight() {
      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'big';

      if (!this.getMainImage(type)) return null;

      return this.$root.offer.images.main[0][type].height;
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.$root.$on('changeOffer', function () {
      _this.initImage();
    });
  }
};

exports.default = OffersImage;

},{}],20:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Пример подключения в шаблоне:
 * <?php $offers = $model->getOffersWithParameter(false) ?>
 * <offers-list-component :offers-list='<?=json_encode($offers)?>' inline-template>
 *  <div>
 *    <label class="item js-radio" v-bind:class="getClass(offer)" v-bind:data-size="offer.text"
 *           v-for="offer in offersList" @click="click(offer)">{{offer.text}}</label>
 *  </div>
 * </offers-list-component>
 */

/**
 * @type {{props: {offersList: {type: ArrayConstructor}}, methods: {isActive(*): *}}}
 */
var OffersListRadio = {
  props: {
    offersList: {
      type: Array
    },
    selectedClass: {
      type: String,
      default: 'is-checked'
    },
    disabledClass: {
      type: String,
      default: 'disabled'
    }
  },
  methods: {
    isActive: function isActive(id) {
      return this.$root.offer.id === id;
    },
    click: function click(offer) {
      if (offer.disabled) return;

      this.$root.offer = offer;
    },
    getClass: function getClass(offer) {
      var classArray = [];

      if (this.isActive(offer.id)) {
        classArray.push(this.selectedClass);
      }

      if (offer.disabled) {
        classArray.push(this.disabledClass);
      }

      return classArray.join(' ');
    }
  }
};

exports.default = OffersListRadio;

},{}],21:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @type {{props: {offersList: {type: ArrayConstructor}}}}
 */
var OffersListSelect = {
  data: function data() {
    return {
      selectedId: null
    };
  },

  props: {
    offersList: {
      type: Array
    }
  },
  methods: {
    setOfferById: function setOfferById(id) {
      var _this = this;

      Object.keys(this.offersList).forEach(function (i) {
        if (_this.offersList[i].id === id) {
          _this.$root.offer = _this.offersList[i];
          return false;
        }
        return true;
      });
    },
    initSelectric: function initSelectric() {
      $(this.$el).find('select.no-default-selectric').selectric({
        disableOnMobile: false,
        nativeOnMobile: true
      }).on('change', function changeHandler() {
        $(this).trigger('input');
      });
    }
  },
  watch: {
    selectedId: function selectedId(offerId) {
      this.setOfferById(offerId);
    }
  },

  mounted: function mounted() {
    var _this2 = this;

    // Устанавливаем значение по умолчанию
    this.$root.$on('setDefaultOffer', function () {
      _this2.selectedId = _this2.$root.offer.id;
      // this.initSelectric();
    });

    this.$nextTick(function () {
      // this.initSelectric();
    });
  }
};

exports.default = OffersListSelect;

},{}],22:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var OffersParam = {
  data: function data() {
    return {
      param: {}
    };
  },

  methods: {
    initParams: function initParams(offer) {
      var outParam = {};

      Object.keys(offer.parameters).forEach(function (i) {
        var param = offer.parameters[i];
        if (param.keys) {
          Object.keys(param.keys).forEach(function (j) {
            outParam[j] = {
              id: param.id,
              name: param.name,
              value: param.value
            };
          });
        } else {
          outParam[param.id] = {
            id: param.id,
            name: param.name,
            value: param.value
          };
        }
      });

      this.param = outParam;
      // this.$root.$data.param = outParam;
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.$root.$on('changeOffer', function (offer) {
      _this.initParams(offer);
    });
  },


  template: '<div></div>'
};

exports.default = OffersParam;

},{}],23:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Пример подключения в шаблоне
 * <price-component :price="this.offer.price" :old-price="this.offer.oldPrice" inline-template>
 * <div class="product-card__price-group">
 *   <div class="product-card__old-price" v-if="oldPrice">
 *     <span v-html="oldPriceFormatted"></span>
 *     <div class="product-card__discount" v-html="economyPercentFormatted"></div>
 *   </div>
 *   <div class="product-card__price" v-html="priceFormatted">
 *     <?php echo PriceHelper::price($model->getPrice(), ' <span class="currency"><span>₽</span></span>');?>
 *   </div>
 * </div>
 * </price-component>
 */

/* eslint-disable */
/**
 * @type {{props: {price: {type: NumberConstructor}, oldPrice: {type: NumberConstructor, default: number}, currencySuffix: {type: StringConstructor, default: string}, emptyPriceText: {type: StringConstructor, default: string}}, computed: {economyPercent(): (number|*), priceFormatted(): *, oldPriceFormatted(): *, economyPercentFormatted(): string}}}
 */
/* eslint-enable */
var Price = {
  props: {
    price: {
      type: Number
    },
    oldPrice: {
      type: Number,
      default: 0
    },
    currencySuffix: {
      type: String,
      default: ' <span class="currency"><span>₽</span></span>'
    },
    emptyPriceText: {
      type: String,
      default: ' <span class="call">Спец. цена, звоните!</span>'
    }
  },
  computed: {
    economyPercent: function economyPercent() {
      if (!this.oldPrice) return 0;
      var percent = (this.price - this.oldPrice) * 100 / this.oldPrice;
      return Math.ceil(percent);
    },
    priceFormatted: function priceFormatted() {
      if (this.price > 0) return this.price.toLocaleString('ru-RU') + this.currencySuffix;
      return this.emptyPriceText;
    },
    oldPriceFormatted: function oldPriceFormatted() {
      if (this.oldPrice > 0) return this.oldPrice.toLocaleString('ru-RU') + this.currencySuffix;
      return this.emptyPriceText;
    },
    economyPercentFormatted: function economyPercentFormatted() {
      return this.economyPercent + '%';
    }
  }
};

exports.default = Price;

},{}],24:[function(require,module,exports){
'use strict';

/* global Vue */

// import Price from './components/PriceComponent.js';

window.initOrderApp = function () {
  if (!$('#js-order-app').length) return false;

  var orderApp = new Vue({
    el: '#js-order-app',
    components: {
      // 'price-component': Price,
    },
    data: function data() {
      return {
        message: 'test'
      };
    },
    mounted: function mounted() {
      $(window).trigger('initializePhoneMasks');
    }
  });

  return orderApp;
};

window.initOrderApp();

},{}],25:[function(require,module,exports){
'use strict';

/* global Vue */

/**
 * Created by dev on 01.07.2022.
 * первичная глобальная инициализация полей в приложении Vue, срабатывает после создания приложения
 * инициализированные и присвоенные поля доступны в  "beforeUpdate: function () {}"  Vue обекта
 * пример <input v-init:depth="100" v-model.number="depth">
 *
 */
window.vueDirective = function () {
  Vue.directive('init', {
    bind: function bind(el, binding, vnode) {
      vnode.context[binding.arg] = binding.value;
    }
  });
};

window.vueDirective();

},{}]},{},[2]);
